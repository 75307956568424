import axios from 'axios';
import download from 'js-file-download';
import { log } from '../../../../utils/notification';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fileDownload = async (params: any) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_BACKEND_URL + 'services/file/download',
      {
        responseType: 'blob',
        params: { absolutePath: params.absolutePath ?? params.params?.absolutePath },
        headers: {
          Accept: 'application/pdf'
        }
      }
    );
    await download(response.data, params.originalName ?? params.params?.originalName);
  } catch (error) {
    log('Function (alias: Download)', { params: params, response: { message: error.message } }, 'Error');
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const base64Download = async (params: any) => {
  try {
    const {
      data,
      name
    } = params.file;

    const buffer = await Buffer.from(data, 'base64');
    await download(buffer, name);

  } catch {
    (error) => {
      log('Function (alias: file/base64Download)', { params: params, response: { message: error.message } }, 'Error');
    };
  }
};

export { fileDownload, base64Download };
