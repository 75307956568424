import axios from 'axios';
import { log, toast } from '../../utils/notification';
import { verifySessionToken } from '../services/tools/service.verifySession';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const runDynamicQuery = async (params: any, widgetData: any) => {

  await verifySessionToken(localStorage.getItem('session'))
    .then((response) => {
      if (response.error) {
        toast.error('run.dynamicQuery.tsx', response.message);
        // localStorage.removeItem('session');
        // window.location.reload();
      }
      else if (response.expired) {
        toast.warning(response.message);
        localStorage.removeItem('session');
        localStorage.setItem('expired', JSON.stringify(true));
        window.location.reload();
      }
    });

  const res = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}services/widgets/data`,
    {
      data: JSON.stringify({
        params

      }),
      session: localStorage.getItem('session')
    },
  );

  if (res.data.data.error && res.data.data.message) {
    toast.error('dynamic query', res.data.data.message);
  }

  // ToDo: check response in API
  const getParams = () => {
    const {action, key, log, widgetKey, timestamp, ..._params } = params;
    _params.params = {action, key, log, widgetKey, timestamp};
    delete _params.params;
    return _params;
  };
  

  log(
    `${widgetData.widget} (key: ${widgetData.key})`,
    {
      params: getParams(),
      response: res.data.data
    },
    res.data.data.error ? 'Error' : 'Widget'
  );
  
  return res.data.data;

};