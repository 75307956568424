import { toast } from '../../../utils/notification';


export const sendPost = async (message: any) => {
  try {
    await fetch(`${process.env.REACT_APP_BACKEND_URL}services/messenger/send-post`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message }),
    });
  } catch (error) {
    if(error.statusCode === 404) {
      toast.error('webpage does not exist', error.message);
    } else {
      toast.error('Error sending message', error.message);
      // throw error;
    }
  }
};
