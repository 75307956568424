import axios from 'axios';

export const serviceFilePreview = async (url: any) => {

  if (!url)
    return null;

  const apiURL = (process.env.REACT_APP_BACKEND_URL + url.substring(1));

  return axios.get(apiURL)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      console.log('Get request error: ', error, { url: url });
    });
};
