import axios from 'axios';
import { verifySessionToken } from '../tools/service.verifySession';
import { jwtDecrypt } from '../tools/service.jwtDecrypt';

export const renderPdf = async (params, id) => {
  try {
    await verifySessionToken(localStorage.getItem('session'))
      .then((response) => {
        if (response.expired) {
          localStorage.removeItem('session');
          localStorage.setItem('expired', JSON.stringify(true));
          window.location.reload();
        }
      });

    const user = await jwtDecrypt(JSON.parse(localStorage.getItem('session')))
      .then((response) => {
        return response.data.user;
      });

    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL + 'services/render-pdf',
      {
        params: params,
        id: id,
        user: user
      }
    );

    console.log(
      `%c Report (key: ${id}) %c `,
      'background: #FF8800; color: #000',
      'background: transparent; color: black',
      {
        params: params,
        response: response.data.data
      }
    );
    return response;
  } catch (error) {
    console.log('getPosts error:', error);
  }
};
