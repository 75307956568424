import request from '../../../request/request';
import { log } from '../../../utils/notification';

export const getLicense = async (system) => {
  return await request<Request.License, any>('POST', 'tools/license', { system: system })
    .then(
      (response) => {
        return response?.data ?? null;
      }
    )
    .catch(
      (error) => {
        log('Request (type: License)', { response: error.message }, 'Error');
      }
    );
};