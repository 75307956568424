import React, { useEffect, useState, useRef } from 'react';
import MenuBar from '../../../components/MenuBar/MenuBar';
import { ColumnDirective, ColumnsDirective, GridComponent, ContextMenu, Filter, Inject, Page, Selection, ExcelExport, PdfExport, Resize, Toolbar, CommandColumn, InfiniteScroll, Sort, Aggregate } from '@syncfusion/ej2-react-grids';
import { setGridData } from '../services/setGridData';
import { insertFilterValues, setFilterValues } from '../services/setFilterValues';
import { WidgetProvider } from '../../WidgetContext';
import { commandClick } from './events/commandClick';
import { useSelector } from 'react-redux';
import { setCustomContextMenu } from '../services/setContextMenu';
import { cellSelecting } from './events/cellSelecting';
import { contextMenuClick } from '../services/contextMenuClick';
import { getSettingsView } from './settings';
import { setKeyDownRemoveFilter } from '../services/setKeydownEvent';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { setFilterDateTimeTemplate } from '../services/setDateTimeFilter';
import { getAggregations } from '../services/aggregations';
import { WidgetContainerStyled, WidgetContentStyled, WidgetLabelStyled } from '../../styles';
import { GridContainerStyled } from '../styles';
import setClassNames from '../services/setClassNames';

const GridWidgetView = ({ navData, actionsState }) => {
  const [gridEditData, setGridEditData] = useState(null);
  const [timeFormat, setTimeFormat] = useState('');
  const [dateFormat, setDateFormat] = useState('');
  // const [dataCounterString, setDataCounterString] = useState('');
  const globalKeyEvent = useSelector((state: any) => state.globalKeyEvent);
  const { t } = useTranslation();

  const gridViewInstance: any = useRef(null);

  const widgetContext = {
    instance: gridViewInstance.current,
    widgetData: navData.widgetData,
    actionsState: actionsState,
    gridEditData: gridEditData
  };

  useEffect(() => {
    const handleKeyDown = (event) => setKeyDownRemoveFilter(event, gridViewInstance.current);
    setGridEditData(null);
    setGridData(setGridEditData, setDateFormat, setTimeFormat, navData, actionsState);
    setTimeout(() => { insertFilterValues(gridViewInstance.current, navData); }, 500);

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      setGridEditData(null);
      setDateFormat('');
      setTimeFormat('');
      // setDataCounterString('');
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [navData.renderID, navData.widgetData.dataID, navData.widgetData.timestamp]);


  const actionComplete = async (args) => {
    if (actionsState && navData) {
      await setFilterValues(args, navData.widgetData.key);
    }
  };

  const setColumnTemplate = (row) => {
    const { elements = [] } = navData.widgetData.menu?.inline ?? {};

    const commandItems = elements.map((button) => {
      const name = button.name.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
      const disabled = row.buttons?.filter(obj => obj.name === name)[0]?.disabled ?? false;
      const key = button.events.filter(obj => obj.type === 'onClick')[0]?.key ?? null;
      const params = { key: key, id: row.id };

      return (
        <button
          key={`${name}-${button.id}`}
          className={`e-control e-btn ${disabled ? 'e-disabled' : ''}`}
          onClick={(e) => commandClick(params, e)}
          disabled={disabled}
        >
          <span className={`e-icons e-${button.icon.name}`}>{button.label}</span>
        </button>
      );
    });
    return commandItems;
  };

  if (!navData?.widgetData || !gridEditData || !timeFormat || !dateFormat) {
    return null;
  }
  
  const rowDataBound = (args) => {
    setClassNames(args);
  };
  
  const settings = getSettingsView(navData);
  const aggregations = getAggregations(navData);

  return (
    <WidgetContainerStyled>
      <WidgetProvider value={widgetContext}>
        {navData.widgetData.menu ?
          <MenuBar
            key={`menu-${navData.widgetData.menu.id}`}
            menu={navData.widgetData.menu}
          /> : null}
        {navData.widgetData.label !== '' ? <WidgetLabelStyled>{t(navData.widgetData.label)}</WidgetLabelStyled> : ''}
        <WidgetContentStyled>
          <GridContainerStyled css={navData.widgetData.style}>
            <GridComponent
              locale={JSON.parse(localStorage.getItem('language'))}
              ref={gridViewInstance}
              id={`grid-${navData.widgetData.key}`}
              key={`grid-${navData.widgetData.key}`}
              height={navData.widgetData.height ? navData.widgetData.height : '100%'}
              rowHeight={28}
              dataSource={gridEditData}
              allowExcelExport={true}
              allowPdfExport={true}
              allowFiltering={true}
              allowResizing={true}
              allowSelection={true}
              allowSorting={true}
              allowPaging={navData.widgetData.allowPaging}
              enableInfiniteScrolling={!navData?.widgetData?.allowPaging}
              selectionSettings={settings.selectionSettings ?? {}}
              filterSettings={settings.filterSettingsMenu}
              contextMenuItems={setCustomContextMenu(navData)}
              pageSettings={navData?.widgetData?.allowPaging ? settings.pageSettings : settings.pageSettingsInfinite}
              cellSelecting={(args) => cellSelecting(args, navData, globalKeyEvent, actionsState)}
              contextMenuClick={(args) => contextMenuClick(args, gridViewInstance.current, navData ? setCustomContextMenu(navData) : null)}
              actionComplete={actionComplete}
              created={() => window.addEventListener('keydown', (event) => setKeyDownRemoveFilter(event, gridViewInstance.current))}
              aggregates={aggregations ?? null}
              rowDataBound={rowDataBound}
            >
              <ColumnsDirective>
                {navData.widgetData.columns.map((column) => {
                  switch (column.type) {
                    case 'boolean':
                      return (
                        <ColumnDirective
                          field={column.name}
                          key={`field-${column.id}`}
                          headerText={t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                          width={column.minWidth}
                          minWidth={column.minWidth}
                          maxWidth={column.maxWidth}
                          customAttributes={{ class: [settings.customAttributes.class, 'code-cell'] }}
                          // template={setColumnTemplate.bind(this)}
                          textAlign={'Center'}
                          type={'boolean'}
                          displayAsCheckBox={true}
                        />
                      );
                    case 'code':
                      return (
                        <ColumnDirective
                          key={`field-${column.id}`}
                          headerText={t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                          width={column.minWidth}
                          minWidth={column.minWidth}
                          maxWidth={column.maxWidth}
                          customAttributes={{ class: [settings.customAttributes.class, 'code-cell'] }}
                          template={setColumnTemplate.bind(this)}
                          textAlign={'Center'}
                        />
                      );
                    case 'datetime':
                    case 'date':
                      // eslint-disable-next-line no-case-declarations
                      const filterTemplateDateTime = setFilterDateTimeTemplate({ dateFormat, timeFormat }, column, gridEditData, setGridEditData);

                      return (
                        <ColumnDirective
                          key={`field-${column.id}`}
                          field={column.name}
                          width={column.minWidth}
                          minWidth={column.minWidth}
                          maxWidth={column.maxWidth}
                          customAttributes={column.name === 'id' ? { class: [settings.customAttributes.class, 'id-cell'] } : settings.customAttributes}
                          headerText={t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                          filterBarTemplate={filterTemplateDateTime}
                          visible={!column.hide}
                          disableHtmlEncode={false}
                          isPrimaryKey={column.name === 'id'}
                          type={column.type === 'date' ? 'date' : 'dateTime'}
                          format={`${dateFormat}${column.type === 'datetime' ? ` ${timeFormat}` : ''}`}
                        />
                      );
                    case 'float':
                      return (
                        <ColumnDirective
                          key={`field-${column.id}`}
                          field={column.name}
                          width={column.minWidth}
                          minWidth={column.minWidth}
                          maxWidth={column.maxWidth}
                          customAttributes={column.name === 'id' ? { class: [settings.customAttributes.class, 'id-cell'] } : settings.customAttributes}
                          headerText={t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                          filter={settings.filterMenu}
                          visible={!column.hide}
                          disableHtmlEncode={false}
                          isPrimaryKey={column.name === 'id'}
                          textAlign="Right"
                          type={aggregations ? 'number' : null}
                          format={aggregations ? 'N2' : null}
                        />
                      );
                    default:
                      return (
                        <ColumnDirective
                          key={`field-${column.id}`}
                          field={column.name}
                          width={column.minWidth}
                          minWidth={column.minWidth}
                          maxWidth={column.maxWidth}
                          customAttributes={column.name === 'id' ? { class: [settings.customAttributes.class, 'id-cell'] } : settings.customAttributes}
                          headerText={t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                          filter={settings.filterMenu}
                          visible={!column.hide}
                          disableHtmlEncode={false}
                          isPrimaryKey={column.name === 'id'}
                          type={'string'}
                        />
                      );
                  }
                })}
              </ColumnsDirective>
              <Inject services={[ContextMenu, Filter, Page, ExcelExport, PdfExport, Selection, Resize, Toolbar, CommandColumn, InfiniteScroll, Sort, Aggregate]} />
            </GridComponent>
          </GridContainerStyled>
        </WidgetContentStyled>
        {/* {!navData?.widgetData?.allowPaging && (
          <DataCounter
            counter={dataCounterString}
          />
        )} */}
      </WidgetProvider>
    </WidgetContainerStyled>
  );
};

GridWidgetView.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object
};

export default GridWidgetView;
