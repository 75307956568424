import { runDynamicQuery } from '../../../api/query/run.dynamicQuery';
import { jwtDecrypt } from '../../../api/services/tools/service.jwtDecrypt';
import { toast } from '../../../utils/notification';

export const setGridData = async (setGridDataState, setDateFormat, setTimeFormat, navData, actionsState) => {
  if (actionsState) {
    try {
      const response = await runDynamicQuery({ ...actionsState, widgetKey: navData.widgetData.key }, navData.widgetData);
      setGridDataState(response.targets);

      const sessionData = JSON.parse(localStorage.getItem('session'));
      if (sessionData) {
        const decryptedData = await jwtDecrypt(sessionData);
        const user = decryptedData.data.user;

        setDateFormat(user.dateFormat);
        setTimeFormat(user.timeFormat);

      }
    } catch (error) {
      toast.error('Error fetching grid data:', error.message);
      throw error;
    }
  }
};