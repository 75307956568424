import axios from 'axios';
import { callFunctions } from './../functions/call.functions';
import { verifySessionToken } from './../services/tools/service.verifySession';
import { toast, log } from '../../utils/notification';

export const runWorkflow = async (key: number, id: number, params: any, file: any, newTab: boolean) => {

  try {
    await verifySessionToken(localStorage.getItem('session'))
      .then((response) => {
        if (response.expired) {
          toast.warning(response.message);
          localStorage.removeItem('session');
          localStorage.setItem('expired', JSON.stringify(true));
          window.location.reload();
        }
        if (response.error) {
          toast.error('runWorkflow.tsx', response.message);
        }
      });

    const formData = new FormData();
    formData.append('key', key?.toString());
    formData.append('id', id ? id?.toString(): null);
    formData.append('params', JSON.stringify(params));
    formData.append('file', file);
    formData.append('session', localStorage.getItem('session'));
  
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}services/workflow`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    if (response.data.functions && !newTab) {
      callFunctions(response.data.functions, null);
    }
    const error = false;
    response?.data.debug?.forEach(
      debug => {
        log(
          debug.step,
          {
            params: debug.params,
            response: debug.response
          },
          debug.style
        );
      }
    );
    return !error ? response.data : null;
  } catch (error) {
    toast.error('runWorkflow error:',  error.message);
    //throw error;
  }
};