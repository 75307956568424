import React, { FC, useEffect, useRef, useState } from 'react';
import login from '../../api/query/user/login';
import { toast } from '../../utils/notification';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent} from '@syncfusion/ej2-react-buttons';
import { Header, Modal } from 'semantic-ui-react';
import { setCookie } from '../../utils/cookies';
import './styles.css';

const LoginTokenDialog: FC<any> = ({ dialogVisible, username, password, resetState, setDialogVisible, version, authenticationMode }) => {
  const [token, setToken] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const textBoxRef = useRef(null);

  useEffect(() => {
    if (dialogVisible) {
      setTimeout(() => { textBoxRef.current.focusIn();}, 300);
    }
  }, [dialogVisible]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (token === '' || username === '' || password === '')
      return null;

    try {
      // const fingerprint = await getFingerprint();
      // const location = await getLocation();

      await login(authenticationMode, username, password, version, token)
        .then((response) => {
          localStorage.setItem('session', JSON.stringify(response.data.data.session));
          localStorage.setItem('translations', JSON.stringify(response.data.data.translations));
          localStorage.setItem('translationsSyncfusion', JSON.stringify(response.data.data.translationsSyncfusion));
          localStorage.setItem('language', JSON.stringify(response.data.data.language));
          localStorage.setItem('expired', JSON.stringify(false));
          // localStorage.setItem('fingerprint', JSON.stringify(response.data.data.fingerprint));
          if (rememberMe) {
            setCookie('sessionCookie', 'cookieVlaue', 3);
          }
          window.location.href = '/app';
          resetState(e);
          setToken('');
          setRememberMe(false);
        });
    } catch (error) {
      if (error.response) {
        localStorage.removeItem('session');
        toast.error('', error.response.data.message);
      }
    }
  };



  return (
    <Modal
      style={{ width: '300px' }}
      open={dialogVisible}
    >
      <Modal.Content
        className="dialog-content"
      >
        <Header
          as="h2"
          textAlign="center"
          className="dialog-header"
        >
                    Authentication
        </Header>
        <form
          action=""
          onSubmit={(e) => handleSubmit(e)}
        >
          {/* <CheckBoxComponent
                        label='remember me'
                        checked={rememberMe}
                        onChange={(args) => setRememberMe(args.target.checked)}
                    /> */}
          <TextBoxComponent
            ref={textBoxRef}
            type="text"
            name="token"
            className='login-form-input'
            value={token}
            change={(e) => { setToken(e.value); }}
            placeholder="OTP-Secret"
            floatLabelType="Auto"
          />
          <div className="token-dialog-buttons">
            <ButtonComponent
              type="Submit"
              cssClass='e-custom'
              style={{ textTransform: 'none' }}
              content={'Confirm'}
            />
            <ButtonComponent
              cssClass='e-custom'
              style={{ textTransform: 'none', marginLeft: '10px' }}
              content={'Cancel'}
              onClick={() => {
                setDialogVisible(false);
                setToken('');
              }}
            />
          </div>
        </form>
      </Modal.Content>
    </Modal>
  );
};

export default LoginTokenDialog;