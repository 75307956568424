import axios from 'axios';
import { toast } from '../utils/notification';

export const writeErrorToLogFile = async (message, stack) => {
  axios.post(process.env.REACT_APP_BACKEND_URL + 'services/error-log', { message, stack })
    .then(() => {
      // console.log('Error logged successfully');
    })
    .catch((error) => {
      toast.error('Write error to file failed:', error.message);
      throw error;
    });
};