import axios from 'axios';
import { jwtDecrypt } from '../tools/service.jwtDecrypt';

export const getWorkspacesPinnedAPI = async () => {
  try {
    const sessionData = JSON.parse(localStorage.getItem('session'));
    const { user } = (await jwtDecrypt(sessionData)).data;

    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}services/workspaces/get-pinned`,
      { user }
    );

    return response.data;
  } catch (error) {
    if (error.statusCode === 404) {
      console.log('Webpage doesn\'t exist.');
    } else {
      throw error; 
    }

  }
};
