import axios from 'axios';

async function getAuthenticationMode() {
  try {
    const response = await axios.post(process.env.REACT_APP_BACKEND_URL + 'auth/get-mode', {});
    if (response.data.success) {
      return response.data.message;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    return { error: true, success: false, message: error.message };
  }
}

export default getAuthenticationMode;
