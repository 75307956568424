import axios from 'axios';
import { jwtDecrypt } from '../tools/service.jwtDecrypt';

export const setLastVisitAPI = async (params) => {

  try {
    const res = await jwtDecrypt(JSON.parse(localStorage.getItem('session')));
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL + 'services/messenger/set-last-visit',
      { user: res.data.user, id: params.id }
    );
    return response.data;
  } catch (error) {
    console.log('getPosts error:', error);
  }
};
