import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { fileTypeFromBuffer } from 'file-type';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { useSelector } from 'react-redux';
import { closeMultiUpload } from '../../store/workspaces/modals/actions';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { log, toast } from '../../utils/notification';
import { run as runActions } from '../../store/workspaces/workspaces/run';
import { runStaticQuery } from '../../api/query/run.staticQuery';
import store from '../../store/store';
import './styles.css';
import { WidgetContainerStyled, WidgetLabelStyled } from '../styles';

type FileType = {
  code: string;
  extensions: Array<string>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sanitizeFile = async (file: any, fileTypes: Array<FileType>) => {
  const rawFile: File = file.rawFile;
  const extension: string = file.name.slice((file.name.lastIndexOf('.') - 1 >>> 0) + 2);
  const buffer = await rawFile.arrayBuffer();
  const hash: string = await CryptoJS.MD5(CryptoJS.lib.WordArray.create(buffer)).toString();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let type: any = await fileTypeFromBuffer(buffer);
  
  if (type === undefined) {
    type = {
      ext: extension,
      mime: fileTypes.find(fileType => fileType.extensions.includes(`.${extension}`)).code
    };
  }
  
  const sanitizedName: string = await encodeURIComponent(rawFile.name);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sanitizedFile: any = new File([rawFile], sanitizedName, { type: type.mime });
  file.name = sanitizedName;
  file.mime = type.mime;
  file.hash = hash;
  file.rawFile = sanitizedFile;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const hashExists = async (upload: any, hash: string) => {
  return await runStaticQuery('getFileByHash', { upload: upload, hash: hash })
    .then(
      fileNode => {
        return fileNode.exists;
      }
    )
    .catch((error) => {
      toast.error('', error.message);
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const UploaderWidget: React.FC<any> = () => {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const modalStates = useSelector((state: any) => state.workspaces.instances.find(instance => instance.isActive).modals);
  const { fileTypes } = modalStates.multiUpload.widgetData;
  
  const getAllowedMimeTypes = () => {
    const allowedMimeTypes = fileTypes.map(fileType => fileType.code);
    return allowedMimeTypes;
  };

  const getAllowedExtensions = () => {
    const allowedExtensions = fileTypes.map(fileType => fileType.extensions).flat().join(',');
    return allowedExtensions;
  };
  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const workspaceActions = useSelector((state: any) => state.workspaces.instances.find(instance => instance.isActive)?.data);
  const [uploaderInstance, setUploaderInstance] = useState(null);
  const [files, setFiles] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    log(
      `${modalStates.multiUpload.widgetData.widget} (key: ${modalStates.multiUpload.widgetData.key})`,
      {
        params: { fileTypes: modalStates.multiUpload.widgetData.fileTypes },
        response: { upload: modalStates.multiUpload.widgetData.upload }
      },
      'Widget'
    );
  }, []);

  let dropContainerRef;

  const asyncSettings = {
    saveUrl: process.env.REACT_APP_BACKEND_URL + 'services/file/upload'
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSelectFile = (args: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    args.filesData.forEach(async (file: any) => {
      await sanitizeFile(file, fileTypes)
        .then(async () => {
          await hashExists(modalStates.multiUpload.widgetData.upload, file.hash)
            .then((exists) => {
              let error = false;

              if (exists === true) {
                error = true;
                toast.warning('File already exists on server: ', file.name);
                args.cancel = true;
                uploaderInstance.remove(file);
              }
              if (files.some(element => file.rawFile.hash === element.hash)) {
                error = true;
                toast.warning('File already exists in the upload list: ', file.name);
                args.cancel = true;
                uploaderInstance.remove(file);
              }
              if (!getAllowedMimeTypes().includes(file.mime)) {
                error = true;
                toast.warning(`MIME type ${file.mime} is not allowed: `, file.name);
                args.cancel = true;
                uploaderInstance.remove(file);
              }
              if (file.statusCode === '0') {
                error = true;

                toast.warning(`File type ${file.type} is not allowed: `, file.name);
                args.cancel = true;
                uploaderInstance.remove(file);
              }
              if (error === false)
                setFiles(files => [...files, file?.rawFile]);
            });

        });
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRemoveFile = (args: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const element: any = args.filesData[0];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFiles(files.filter((elem: any) => {
      return !(elem?.hash === element?.rawFile.hash);
    }));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFailure = (args: any) => {
    if (args.e.currentTarget.statusText !== '')
      toast.error('', args.e.currentTarget.statusText);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUploading = (args: any) => {
    const params = {
      directory: workspaceActions?.directory,
      upload: modalStates.multiUpload.widgetData.upload
    };
    args.customFormData.push({ 'params': JSON.stringify(params) });
  };


  const onActionComplete = () => {
    const eventKey = modalStates.multiUpload.widgetData?.events?.find((event) => event.type === 'onClick')?.key;
    runActions(eventKey, workspaceActions.id, { ...workspaceActions, upload: modalStates.multiUpload.widgetData.upload });
  };


  return (
    <WidgetContainerStyled>
      <Modal
        open={modalStates.multiUpload.isOpen}
        centered={true}
        closeOnDimmerClick={false}
        id={modalStates.multiUpload.widgetData.key}
        className={'dialog-upload-container'}
        style={{
          height: modalStates.multiUpload.widgetData.height,
          width: modalStates.multiUpload.widgetData.width,
          overflow: 'hidden'
        }}
      >
        <Modal.Header>
          {
            modalStates.multiUpload.widgetData.label !== '' ? <WidgetLabelStyled>{t(modalStates.multiUpload.widgetData.label)} </WidgetLabelStyled> : ''
          }
          <div
            className={'e-icons e-close label-icon modal-close-icon'}
            onClick={() => {
              store.dispatch(closeMultiUpload({}));
            }}
          />
        </Modal.Header>
        <Modal.Content>
          <div style={{ display: 'flex' }}>
            <ButtonComponent
              id={'uploader-browse'}
              cssClass='e-custom upload-button'
              iconCss='e-icons e-file-new'
              style={{ textTransform: 'none' }}
              key={'browse'}
              onClick={() => {
                document
                  .getElementsByClassName('e-file-select-wrap')[0]
                  .querySelector('button')
                  .click();
                return false;
              }}
            />
            <ButtonComponent
              id={'uploader-clear-all'}
              cssClass='e-custom uploader-button'
              content={'clear all'}
              style={{ textTransform: 'none' }}
              key={'clear all'}
              onClick={() => {
                setFiles([]);
                uploaderInstance.clearAll();
              }}
            />
            <ButtonComponent
              id={'uploader-upload'}
              cssClass='e-custom uploader-button'
              style={{ textTransform: 'none' }}
              content={'upload'}
              key={'upload'}
              onClick={() => {
                uploaderInstance.upload();
              }}
            />
          </div>

          <div
            className='dialog-upload-preview'
            ref={dropContainerRef}
          >
            <span
              className="e-icons e-plus e-medium"
              id="dropText"
              ref={dropContainerRef}
            />
            <UploaderComponent
              locale={JSON.parse(localStorage.getItem('language'))}
              id='file-upload'
              type='file'
              sequentialUpload={true}
              ref={(scope) => { setUploaderInstance(scope); }}
              autoUpload={false}
              asyncSettings={asyncSettings}
              allowedExtensions={getAllowedExtensions()}
              maxFileSize={100 * 1024 * 1024}
              removing={onRemoveFile}
              selected={onSelectFile}
              failure={onFailure}
              uploading={onUploading}
              actionComplete={onActionComplete}
            />
          </div>
        </Modal.Content>
      </Modal>
    </WidgetContainerStyled >
  );
};
export default UploaderWidget;
