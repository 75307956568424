/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { WidgetProvider } from '../../WidgetContext';
import { runDynamicQuery } from '../../../api/query/run.dynamicQuery';
import MenuBar from '../../../components/MenuBar/MenuBar';
import PropTypes from 'prop-types';
import axios from 'axios';
import { FileViewerContainerStyled } from './styles';
import { toast } from '../../../utils/notification';
import WebViewer from '@pdftron/webviewer';
import { WidgetContainerStyled } from '../../styles';
import { getLicense } from '../../../api/services/tools/service.getLicense';

const FileViewer = ({ navData, actionsState }) => {
  const viewer = useRef(null);
  const [ loaded, setLoaded ] = useState(false);

  useEffect(() => {

    if(loaded) return;

    const fetchData = async () => {
      try {
        const license = await getLicense('apryse');
        
        const instance = await WebViewer({
          path: 'webviewer',
          licenseKey: license,
          fullAPI: true,
          isReadOnly: true,
          enableAnnotations: true,
          enableFilePicker: false
        }, viewer.current);

        setLoaded(true);
  
        const response = await runDynamicQuery({ ...actionsState, widgetKey: navData.renderID }, navData.widgetData);
  
        if (!Object.keys(response).length) return;
  
        const { options } = response;
        const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}services/download`, { files: response[options.data] });
        const files = data.files.map(({ name, uri }) => ({ fileName: name, uri: uri }));
        instance.UI.loadDocument(files[0].uri);

        const { documentViewer } = instance.Core;
  
        documentViewer.addEventListener('documentLoaded', () => {

        });
      } catch (error) {
        toast.error('Error fetching document:', error.message);
      }
    };
  
    fetchData();
  }, [navData]);
  

  return (
    <WidgetContainerStyled>
      <WidgetProvider value={viewer}>
        {navData.widgetData.menu ? (
          <MenuBar
            key={`menu-${navData.widgetData.menu.id}`}
            menu={navData.widgetData.menu}
          />
        ) : null}
        {/* <WidgetLabelStyled>{t(navData.label)} </WidgetLabelStyled> */}
        <FileViewerContainerStyled>
          <WidgetProvider value={{ instance: viewer }}>
            {navData.widgetData.menu && <MenuBar key={`menu-${navData.widgetData.menu.id}`} menu={navData.widgetData.menu} />}
            <div className="webviewer" ref={viewer} style={{height: '100%'}}></div>
          </WidgetProvider>
        </FileViewerContainerStyled>
      </WidgetProvider>
    </WidgetContainerStyled>
  );
};

FileViewer.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object,
};

export default FileViewer;
