import axios from 'axios';
import { jwtDecrypt } from '../tools/service.jwtDecrypt';
import store from '../../../store/store';
import { toast } from '../../../utils/notification';


export const setWorkspacesPinnedAPI = async (workspaceIndex, pinned) => {
  try {
    const workspace = store.getState().workspaces.instances[workspaceIndex];

    const data = pinned
      ? {
        ...workspace,
        data: workspace.breadcrumb.currentPage ?? workspace.data,
        isActive: false,
        widgets: {},
        modals: {},
      }
      : {
        uuid: workspace?.uuid,
      };

    const sessionData = JSON.parse(localStorage.getItem('session'));
    const userUuid = (await jwtDecrypt(sessionData)).data.user.uuid;

    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}services/workspaces/set-pinned`,
      { data, pinned, user: { uuid: userUuid } }
    );

    return response.data;
  } catch (error) {
    toast.error('setWorkspacesPinnedAPI:',  error.message);
    throw error;
  }
};
