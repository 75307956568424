import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { log, toast } from '../utils/notification';

type RequestMethod = 'DELETE' | 'GET' | 'HEAD' | 'OPTIONS' | 'PATCH' | 'POST' | 'PUT' | 'TRACE';

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
      toast.warning('Request', 'Timeout');
    } else if (error.code === 'ECONNREFUSED') {
      toast.warning('Network', 'Connection Refused');
    } else if (error.code === 'ERR_NETWORK ') {
      toast.error('Network', 'Backend not available');
    } else {
      // ToDo: Log other errors 
      // log('Request', {response: error.message}, 'Error');
    }
    log('Request', {response: error.message}, 'Error');
    return Promise.reject(error);
  }
);

export default async function request<RequestType extends Record<string, any>, ResponseType extends Record<string, any> = Record<string, any>>(
  method: RequestMethod,
  endpoint: string,
  parameters: RequestType = {} as RequestType,
  headers?: any,
): Promise<ResponseType> {
  try {
    const baseUrl = process.env.REACT_APP_BACKEND_URL;

    if (!baseUrl) {
      throw new Error('API_BASE_URL is not defined in your .env file.');
    }

    const hasBody = method === 'PATCH'
      || method === 'POST'
      || method === 'PUT';

    const config: AxiosRequestConfig = {
      method,
      url: endpoint,
      params: hasBody ? undefined : parameters,
      data: hasBody ? parameters : undefined,
      headers
    };

    try {
      const response: AxiosResponse<ResponseType> = await api.request(config);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  } catch (error) {
    toast.error('request error', error.message);
  }
}
