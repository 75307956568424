import axios from 'axios';
import { log } from '../../../utils/notification';

export const jwtDecrypt = async (token) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL + 'services/dec',
      { data: token }
    );
    return response.data;
  } catch (error) {
    log('jwt decrypt error: ', error.message, 'Error');
  }
};
