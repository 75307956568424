import React, { useEffect, useState } from 'react';
import { WidgetProvider } from '../../WidgetContext';
import MenuBar from '../../../components/MenuBar/MenuBar';
import PropTypes from 'prop-types';
import { log } from '../../../utils/notification';
import { runDynamicQuery } from '../../../api/query/run.dynamicQuery';
import { addTrailingSlash, objectToString } from '../../../utils/url';
import { StyledContainer } from './style';

const CadViewer = ({ navData, actionsState }) => {
  const [url, setUrl] = useState(null);

  useEffect(() => {
    // const w: webvis.RenderMode = webvis.RenderMode.FacesTopology
    const fetchData = async () => {
      try {
        const response = await runDynamicQuery({ ...actionsState, widgetKey: navData.renderID }, navData.widgetData);
        if (Object.keys(response).length !== 0) {
          response.files.forEach((file) => {
            const params = {
              initData: `${addTrailingSlash(process.env.REACT_APP_3DHUB_REVERSE_BACKEND_URL)}services/file/download?uuid=${file.uuid}`,
              backgroundColor: 'ffffff',
              language: 'de',
              addButtonEnabled: false,
              annotationEditingEnabled: false,
              cappingCreationEnabled: false,
              renderMode: '2',
              autoplay: true
            };

            const url = `${addTrailingSlash(process.env.REACT_APP_3DHUB_URL)}/repo/webvis/webvis-app-autoui.html`;

            setUrl(`${url}?${objectToString(params)}`);
          });
        }
      } catch (error) {
        log('error fetch cad data: ', error.message, 'Error');
      }
    };
    fetchData();
  }, [navData.widgetData.dataID, actionsState?.key, actionsState.timestamp]);

  const widgetContext = {
    widgetData: navData.widgetData
  };

  return (
    url &&
    <WidgetProvider value={widgetContext}>
      {
        navData.widgetData.menu
          ? <MenuBar
            key={`menu-${navData.widgetData.menu.id}`}
            menu={navData.widgetData.menu}
          />
          : null
      }
      <StyledContainer>
        <embed
          src={url}
        />
      </StyledContainer>
    </WidgetProvider>
  );
};

CadViewer.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object,
};

export default CadViewer;
