import axios from 'axios';
import { toast } from '../../../utils/notification';
import { jwtDecrypt } from '../tools/service.jwtDecrypt';


export const setWorkspacesSequenceAPI = async (pinnedWorkspacesUuids) => {
  try {
    const sessionData = JSON.parse(localStorage.getItem('session'));
    const userUuid = (await jwtDecrypt(sessionData)).data.user.uuid;


    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}services/workspaces/set-pinned-sequence`,
      { pinnedWorkspacesUuids, user: { uuid: userUuid } }
    );

    return response.data;
  } catch (error) {
    toast.error('setWorkspacesPinnedAPI:',  error.message);
    throw error;
  }
};
