import { addIncomingPosts, setBadgeVisible } from '../../../store/workspaces/chat/actions';
import { jwtDecrypt } from '../tools/service.jwtDecrypt';
import store from '../../../store/store';
import { sendPost } from '../chat/post.send';
import { scrollDownChatBody } from '../../../widgets/chat/services/handleScroll';
import { toast } from '../../../utils/notification';

let eventSourceUrl;
let eventSource;

export const setEventSourceHeartBeat = async () => {
  try {
    const res = await jwtDecrypt(JSON.parse(localStorage.getItem('session')));
    eventSourceUrl = `${process.env.REACT_APP_BACKEND_URL}services/heartbeat?uuid=${res.data.user.uuid}`;
    eventSource = new EventSource(eventSourceUrl);

    eventSource.onopen = () => {
      try {
        sendPost('connected');
        store.dispatch(setBadgeVisible(false, null));
      } catch (error) {
        toast.error('heartbeat error: not connected', error.message);
        setTimeout(setEventSourceHeartBeat, 5000);
      }
    };
    eventSource.addEventListener('sendPost', (event: any) => {

      try {
        const data = JSON.parse(event.data);
        store.dispatch(addIncomingPosts({...data.response, chatId: data.chatId}));
        scrollDownChatBody();
      } catch (error) {
        toast.error('heartbeat error: sendPost', error.message);
        setTimeout(setEventSourceHeartBeat, 5000);
      }
    });

    eventSource.addEventListener('newPostsCount', (event: any) => {
      if (event?.type === 'newPostsCount' && event?.data) {
        const chats: any[] = JSON.parse(event?.data);
        
        if (chats.length > 0) {
          const chatState = store.getState().workspaces.instances.find(instance => instance.isActive)?.chat;
      
          const totalNewCount = chats
            .filter((chat) => chat.id !== chatState.id)
            .map((chat) => chat.count)
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
      
          if (totalNewCount !== 0) {
            store.dispatch(setBadgeVisible(true, totalNewCount));
          }else{
            store.dispatch(setBadgeVisible(false, null));
          }
        }
      }
    });

    eventSource.onerror = () => {
      try {
        eventSource.close();
      } catch (error) {
        toast.error('heartbeat error: connection',  error.message);
        eventSource.close();
        setTimeout(setEventSourceHeartBeat, 5000);
      }
    };

  } catch (error) {
    toast.error('Error decrypting JWT and setting up EventSource',  error.message);
    setTimeout(setEventSourceHeartBeat, 5000);
  }
};

export const closeEventSourceHeartbeat = async () => {
  return eventSource.close();
};
