import axios from 'axios';

const checkDBConnection = async () => {
  try {
    const response = await axios.post(process.env.REACT_APP_BACKEND_URL + 'check-db-connection', {});
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export default checkDBConnection;
