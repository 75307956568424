import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  padding: 12px;
  background-color: white;
  border: 1px solid !important;
  border-color: rgb(225, 220, 220) !important;
  border-radius: 5px !important;

  embed {
    height: 100%;
    width: 100%;
  }

  iron-pages {
    box-shadow: none!important;
  }
`;