import axios from 'axios';

const login = async (authenticationMode: string, username: string, password: string, version: string, token: string = null) => {
  return axios.post(process.env.REACT_APP_BACKEND_URL + 'auth/signin', {
    authenticationMode,
    username,
    password,
    token,
    version
  });
};

export default login;