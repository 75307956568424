import React, { useState, useRef, useEffect } from 'react';
import MenuBar from '../../../components/MenuBar/MenuBar';
import { ColumnDirective, ColumnsDirective, GridComponent, ContextMenu, Edit, Filter, Group, Inject, Page, Selection, ExcelExport, PdfExport, Resize, Toolbar, InfiniteScroll, Sort, Aggregate } from '@syncfusion/ej2-react-grids';
import { DropDownList } from '@syncfusion/ej2-react-dropdowns';
import { WidgetProvider } from '../../WidgetContext';
// import { setDataCounter } from '../services/dataCounter/setDataCounter';
import { setGridData } from '../services/setGridData';
import { setCustomContextMenu } from '../services/setContextMenu';
import { contextMenuClick } from '../services/contextMenuClick';
import { useTranslation } from 'react-i18next';
// import DataCounter from '../services/dataCounter/dataCounter';
import { setBatchEditData } from './events/setBatchEditData';
import { getSettingsUpdate } from './settings';
import { getEditType } from './events/getEditType';
import { insertFilterValues, setFilterValues } from '../services/setFilterValues';
import { setKeyDownRemoveFilter } from '../services/setKeydownEvent';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import PropTypes from 'prop-types';
import { setFilterDateTimeTemplate } from '../services/setDateTimeFilter';
import { getAggregations } from '../services/aggregations';
import { GridContainerStyled } from '../styles';
import { WidgetContainerStyled, WidgetContentStyled, WidgetLabelStyled } from '../../styles';
import setClassNames from '../services/setClassNames';

const GridWidgetUpdate = ({ navData, actionsState }) => {
  const [gridData, setGridDataState] = useState(null);
  const [gridEditData, setGridEditData] = useState([]);
  const [timeFormat, setTimeFormat] = useState('');
  const [dateFormat, setDateFormat] = useState('');
  // const [dataCounterString, setDataCounterString] = useState('');
  const { t } = useTranslation();

  const gridUpdateInstance = useRef(null);

  const widgetContext = {
    instance: gridUpdateInstance.current,
    gridEditData: gridEditData,
    widgetData: navData.widgetData,
    actionsState: actionsState
  };

  useEffect(() => {
    const handleKeyDown = (event) => setKeyDownRemoveFilter(event, gridUpdateInstance.current);
    setGridDataState(null);
    setGridData(setGridDataState, setDateFormat, setTimeFormat, navData, actionsState);
    setTimeout(() => { insertFilterValues(gridUpdateInstance.current, navData); }, 500);

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      setGridDataState(null);
      setGridEditData([]);
      setDateFormat('');
      setTimeFormat('');
      // setDataCounterString('');
      window.removeEventListener('keydown', (event) => setKeyDownRemoveFilter(event, gridUpdateInstance.current));
    };
  }, [navData.renderID, navData.widgetData.dataID, navData.widgetData.timestamp]);


  const created = () => {
    setGridEditData(gridData);
    window.addEventListener('keydown', (event) => setKeyDownRemoveFilter(event, gridUpdateInstance.current));
  };

  const actionComplete = async (args) => {
    if (actionsState && navData) {
      await setFilterValues(args, navData.widgetData.key);
    }
    // await setDataCounter(args, { setState: setDataCounterString, t });
    await setBatchEditData(gridEditData, setGridEditData, args);
  };

  // TODO outsource
  let elem;
  const dropdownTempObj = useRef([]);

  const dropdownElement = {
    create: (args) => {
      elem = document.createElement('input');
      elem.id = 'dropedit' + args.column.field;
      return elem;
    },
    destroy: () => {
      dropdownTempObj.current[0]?.ej2_instances[0]?.destroy();
      dropdownTempObj.current?.shift();
    },
    read: (args) => {
      const value = args.ej2_instances[0]?.value;
      dropdownTempObj.current?.push(args);
      return value;
    },
    write: (args) => {
      let dropDownData = null;
      if (navData.widgetData.columns !== undefined) {
        navData.widgetData.columns.forEach((element) => {
          if (element.name === args.column.field && element.type === 'list') {
            dropDownData = element.dropDownData;
          }
        });
      }
      const dropdownTempObjInstance = new DropDownList({
        dataSource: dropDownData.sort(),
        fields: { text: args.column.field, value: args.column.field },
        value: args.rowData[args.column.field],
        floatLabelType: 'Auto',
        popupHeight: '220px',
        placeholder: args.column.field.charAt(0).toUpperCase() + args.column.field.slice(1),
      });
      dropdownTempObjInstance.appendTo(args.element);
    },
  };

  /* numeric cell*/
  const numericCell = {
    params: { decimals: 1 },
  };

  /* boolean cell */
  let booleanEditObject;
  const booleanEditCell = {
    create: () => {
      elem = document.createElement('input');
      return elem;
    },
    destroy: () => {
      const element = elem;
      const checkedElement = element.checked;
      gridData.forEach((element) => {
        if (checkedElement !== element.selected) {
          element.selected = checkedElement;
          element.test_boolean = checkedElement;
        }
      });
    },
    read: () => {
      const element = elem;
      return element.checked;
    },
    write: (args) => {
      const _args = args;
      booleanEditObject = new CheckBoxComponent({
        label: _args.column.field,
        checked: _args.rowData.test_boolean,
      });
      booleanEditObject.appendTo(elem);
    },
  };

  const getEditTypeCell = (column) => {
    if (column.editable === true) {
      switch (column.type) {
        case 'list':
          return dropdownElement;
        case 'string':
          return null;
        case 'textarea':
          return null;
        case 'integer':
          return numericCell;
        case 'boolean':
          return booleanEditCell;
        default:
          return null;
      }
    } else {
      return null;
    }
  };


  if (!navData?.widgetData || !gridData || !timeFormat || !dateFormat) {
    return null;
  }
  
  const rowDataBound = (args) => {
    setClassNames(args);
  };

  const settings = getSettingsUpdate(navData);
  const aggregations = getAggregations(navData);

  return (
    <WidgetContainerStyled>
      <WidgetProvider value={widgetContext}>
        {navData.widgetData.menu ? (
          <MenuBar
            key={`menu-${navData.widgetData.menu.id}`}
            menu={navData.widgetData.menu}
          />
        ) : null}
        {navData.widgetData.label !== '' ? <WidgetLabelStyled>{t(navData.widgetData.label)} </WidgetLabelStyled> : ''}
        <WidgetContentStyled>
          <GridContainerStyled css={navData.widgetData.style}>
            <GridComponent
              locale={JSON.parse(localStorage.getItem('language'))}
              id={`grid-${navData.widgetData.key}`}
              ref={(g) => (gridUpdateInstance.current = g)}
              key={`grid-${navData.widgetData.key}`}
              height={'100%'}
              rowHeight={28}
              className={`grid-widget${navData.widgetData.type === 'link' && navData.widgetData.rowSelection === 'single' ? ' e-link-grid' : ''}`}
              dataSource={gridData}
              allowExcelExport={true}
              allowPdfExport={true}
              allowFiltering={true}
              allowResizing={true}
              allowSelection={true}
              allowSorting={true}
              allowPaging={navData.widgetData.allowPaging}
              enableInfiniteScrolling={false}
              editSettings={settings.editSettings}
              filterSettings={settings.filterSettingsMenu}
              contextMenuItems={setCustomContextMenu(navData)}
              pageSettings={navData?.widgetData?.allowPaging ? settings.pageSettings : settings.pageSettingsInfinite}
              created={created}
              contextMenuClick={(args) => contextMenuClick(args, gridUpdateInstance.current, navData ? setCustomContextMenu(navData) : null)}
              actionComplete={actionComplete}
              aggregates={aggregations}
              rowDataBound={rowDataBound}
            >
              <ColumnsDirective>
                {navData.widgetData.columns.map((column) => {
                  switch (column.type) {
                    case 'boolean':
                      return (
                        <ColumnDirective
                          key={`field-${column.id}`}
                          field={column.name}
                          width={column.minWidth}
                          minWidth={column.minWidth}
                          maxWidth={column.maxWidth}
                          customAttributes={column.name === 'id' ? { class: [settings.customAttributes.class, 'id-cell'] } : settings.customAttributes}
                          headerText={t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                          visible={!column.hide}
                          edit={getEditTypeCell(column)}
                          isPrimaryKey={column.name === 'id'}
                          allowEditing={column.editable}
                          editType={getEditType(column)}
                          type={'boolean'}
                          displayAsCheckBox={true}
                        />
                      );
                    case 'code':
                      return (
                        <ColumnDirective
                          key={`field-${column.id}`}
                          headerText={t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                          width={column.minWidth}
                          minWidth={column.minWidth}
                          maxWidth={column.maxWidth}
                          customAttributes={{ class: [settings.customAttributes.class, 'code-cell'] }}
                          textAlign={'Center'}
                        />
                      );

                    case 'datetime':
                    case 'date':
                      // eslint-disable-next-line no-case-declarations
                      const filterTemplateDateTime = setFilterDateTimeTemplate({ dateFormat, timeFormat }, column, gridEditData, setGridEditData);

                      return (
                        <ColumnDirective
                          key={`field-${column.id}`}
                          field={column.name}
                          width={column.minWidth}
                          minWidth={column.minWidth}
                          maxWidth={column.maxWidth}
                          customAttributes={column.name === 'id' ? { class: [settings.customAttributes.class, 'id-cell'] } : settings.customAttributes}
                          headerText={t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                          filterBarTemplate={filterTemplateDateTime}
                          visible={!column.hide}
                          disableHtmlEncode={false}
                          edit={getEditTypeCell(column)}
                          isPrimaryKey={column.name === 'id'}
                          allowEditing={column.editable}
                          editType={getEditType(column)}
                          type={column.type === 'date' ? 'date' : 'dateTime'}
                          format={`${dateFormat}${column.type === 'datetime' ? ` ${timeFormat}` : ''}`}
                        />
                      );
                    default:
                      return (
                        <ColumnDirective
                          key={`field-${column.id}`}
                          field={column.name}
                          width={column.minWidth}
                          minWidth={column.minWidth}
                          maxWidth={column.maxWidth}
                          customAttributes={column.name === 'id' ? { class: [settings.customAttributes.class, 'id-cell'] } : settings.customAttributes}
                          headerText={t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                          filter={settings.filterMenu}
                          visible={!column.hide}
                          disableHtmlEncode={false}
                          edit={getEditTypeCell(column)}
                          isPrimaryKey={column.name === 'id'}
                          allowEditing={column.editable}
                          editType={getEditType(column)}
                          type={'string'}
                        />
                      );
                  }
                })}
              </ColumnsDirective>
              <Inject services={[ContextMenu, Filter, Page, ExcelExport, Edit, Group, PdfExport, Selection, Resize, Toolbar, InfiniteScroll, Sort, Aggregate]} />
            </GridComponent>
          </GridContainerStyled>
        </WidgetContentStyled>
        {/* {!navData?.widgetData?.allowPaging && (
          <DataCounter
            counter={dataCounterString}
          />
        )} */}
      </WidgetProvider>
    </WidgetContainerStyled>
  );
};

GridWidgetUpdate.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object
};

export default GridWidgetUpdate;
