import axios from 'axios';

export const getPosts = async (params) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL + 'services/messenger/get-posts',
      { params }
    );
    return response.data;
  } catch (error) {
    console.log('getPosts error:', error);
  }
};
