import axios from 'axios';
import { verifySessionToken } from '../services/tools/service.verifySession';
import { toast, log } from '../../utils/notification';

export const runStaticQuery = async (queryAlias: string, params: any) => {
  try {
    const sessionResponse = await verifySessionToken(localStorage.getItem('session'));
    if (sessionResponse.expired) {
      toast.warning(sessionResponse.message);
      localStorage.removeItem('session');
      localStorage.setItem('expired', JSON.stringify(true));
      window.location.reload();
      return null;
    }
    if(sessionResponse.error) return null;

    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}services/queries`,
      {
        data:
          JSON.stringify({
            params,
            queryAlias
          }),
        session: localStorage.getItem('session')
      }
    );

    response?.data.debug?.forEach(
      debug => {
        log( debug.step, { params: debug.params, response: debug.response }, debug.style);
      }
    );

    return response.data.data;
  } catch (error) {
    toast.error(queryAlias, error.message);
  }
};