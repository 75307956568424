import axios from 'axios';
import { log, toast } from '../../../utils/notification';


export const verifySessionToken = async (token) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL + 'services/verify-session',
      { token }
    );
    return response.data;
  } catch (error) {
    toast.error('Request', error.message);
    log('Request (type: verifySessionToken)', { response: error.message }, 'Error');
    // throw error;
  }
};
