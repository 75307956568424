import axios from 'axios';
import { log } from '../../../utils/notification';

export const jwtEncrypt = async (text) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL + 'services/enc',
      { data: text }
    );
    return response.data;
  } catch (error) {
    log('jwt encrypt error: ', error.message, 'Error');
  }
};
